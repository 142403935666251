import BasePlugin from '../BasePlugin'

export default class CalculateInvestmentExpensesBudgetCostWithVATAsyncCommand extends BasePlugin {
  async execute () {
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/CalculateInvestmentExpensesBudgetCostWithVATAsyncCommand`,
      { 'initiatorUserId': 1, 'async': 'true' },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      console.log('success')
    })
  }
}
